import styled from "styled-components"
import Announcement from "../components/Announcement"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import { Add, Remove, StayPrimaryPortraitRounded } from '@material-ui/icons'
import {mobile} from "../responsive"
import { useDispatch, useSelector } from "react-redux"
import StripeCheckout from "react-stripe-checkout"
import logo from "../assets/logo.png"
import { useEffect, useState } from "react"
import { publicRequest, userRequest } from "../requestMethods"
import { Link, useHistory } from "react-router-dom"
import { setOrderId, clearCart } from "../redux/apiCalls"
import Tippy from "@tippyjs/react"
import 'tippy.js/dist/tippy.css'



const KEY = process.env.REACT_APP_STRIPE_TEST;

const Container = styled.div`

`

const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: "5px"})}
`

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TopButton = styled.button`
  padding: 15px;
  font-weight: 600;
  border: ${(props)=>props.type === "filled" && "none"};
  background-color: ${(props)=>props.type === "filled" ? "black" : "transparent"};
  color: ${(props)=>props.type === "filled" && "white"};
  ${mobile({ padding: "10px"})}
`

const TopTexts = styled.div`
${mobile({ display: "none"})}
`

const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${mobile({ marginTop: "10px"})}
`
const Info = styled.div`
  flex: 3;
`

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column", marginBottom: "5px"})}
`
const ProductDetail = styled.div`
  flex: 2;
  display: flex;
  ${mobile({ justifyContent: "center", alignItems: "center"})}
`
const Image = styled.img`
  width: 20%;
  ${mobile({ width: "50%", height: "50%"})}
`
const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
const ProductName = styled.span`
  
`
const ProductID = styled.span`
  
`

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const ProductAmount = styled.div`
  font-size: 24px;
  margin: 5px;
`

const ProductPrice = styled.div`
  font-size: 35px;
  font-weight: 200;
`

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 40vh;
  ${mobile({ height: "60vh"})}
`

const SummaryTitle = styled.h1`
  font-weight: 200;
`

const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${props=>props.type === "total" && "500"};
  font-size: ${props=>props.type === "total" && "24px"};
`

const SummaryItemText = styled.span``

const SummaryItemPrice = styled.span``

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
`

const Cart = () => {
  const cart = useSelector(state=>state.cart);
  const [stripeToken, setStripeToken] = useState(null)
  const history = useHistory();
  const dispatch = useDispatch();

  const onToken = (token) => {
    setStripeToken(token);
  };

  const sendToStripe = async () => {
    try {
      const res = await publicRequest.post("/checkout/stripify", {
        items: cart.products,
      }).then((res) => {
        if (res){
          setOrderId(dispatch, res.data.id)
          window.location = res.data.url
        } else {
          console.log("Error with getting Stripe Link")
        }
      })
    } catch(err) {
      console.log(err);
    }
  }

  // useEffect(()=>{
  //   const makeRequest = async () => {
  //     try{
  //       const res = await publicRequest.post("/checkout/payment", {
  //         tokenId: stripeToken.id,
  //         amount: cart.total*100,
  //       });
  //       history.push("/success", {
  //         stripeData: res.data,
  //         products: cart, });
  //     } catch {}
  //   };
  //   stripeToken && makeRequest();
  // }, [stripeToken,cart.total,history]);

  return (
    <Container>
        <Navbar/>
        <Announcement/>
        <Wrapper>
            <Title>Your Cart</Title>
            <Top>
              <Link to="/">
                <TopButton style={{cursor:"pointer"}}>Continue Shopping</TopButton>
              </Link>
              <TopTexts>
                  <TopText>Shopping Bag</TopText>
                  <TopText>Your Wishlist</TopText>
              </TopTexts>
                <TopButton onClick={() => clearCart(dispatch,{ cart })}style={{cursor:"pointer"}} type="filled">Clear Cart</TopButton>
            </Top>
            <Bottom>
                <Info>
                  {cart.products.map(product=>(
                  <Product>
                    <ProductDetail>
                      <Image src={product.img}/>
                      <Details>
                        <ProductName><b>Product:</b> {product.title}</ProductName>
                        <ProductID><b>ID:</b> {product._id}</ProductID>
                      </Details>
                    </ProductDetail>
                    <PriceDetail>
                      <ProductAmountContainer>
                        <Remove/>
                        <ProductAmount>{product.quantity}</ProductAmount>
                        <Add/>
                      </ProductAmountContainer>
                      <ProductPrice>$ {(product.price*product.quantity).toFixed(2)}</ProductPrice>
                    </PriceDetail>
                  </Product>
                  ))}
                  <Hr/>
                </Info>
                <Summary>
                  <SummaryTitle>Order Summary</SummaryTitle>
                  <SummaryItem>
                    <SummaryItemText>Subtotal</SummaryItemText>
                    <SummaryItemPrice>$ {(cart.total).toFixed(2)}</SummaryItemPrice>
                  </SummaryItem>
                  <SummaryItem>
                    <SummaryItemText>Estimated Shipping</SummaryItemText>
                    <SummaryItemPrice>$3.04</SummaryItemPrice>
                  </SummaryItem>
                  <SummaryItem>
                    <SummaryItemText>Shipping Discount</SummaryItemText>
                    <SummaryItemPrice>-$3.04</SummaryItemPrice>
                  </SummaryItem>
                  <SummaryItem type="total">
                    <SummaryItemText>Total</SummaryItemText>
                    <SummaryItemPrice>$ {(cart.total).toFixed(2)}</SummaryItemPrice>
                  </SummaryItem>
                  {/* <StripeCheckout
                  name="Shepherd Market"
                  image = {logo}
                  billingAddress
                  shippingAddress
                  description={`Your total is $${cart.total}`}
                  amount={cart.total*100}
                  token={onToken}
                  stripeKey={KEY}
                  >
                    <Button>Checkout Now</Button>
                  </StripeCheckout> */}
                  <Button onClick={() => sendToStripe()}>Checkout Now</Button>
                    {/*<Tippy content="Website is still under development. Payment and shipping/pick-up options coming soon!">
                      <Button style={{backgroundColor:"#D3D3D3"}}>Checkout Now</Button>
                    </Tippy>*/}
                </Summary>
            </Bottom>
        </Wrapper>
        <Footer/>
    </Container>
  )
}

export default Cart