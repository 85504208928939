import { css } from "styled-components"

export const mobile = (props) =>{
    return css`
        @media only screen and (max-width: 414px){
            ${props}
        }
    `;
};

export const halfScreen = (props) =>{
    return css`
        @media only screen and (max-width: 1022px){
            ${props}
        }
    `;
};

export const productsErr = (props) =>{
    return css`
        @media only screen and (max-width: 1258px){
            ${props}
        }
    `;
};