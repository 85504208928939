import Home from "./pages/Home";
import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Success from "./pages/Success";
import { useDispatch, useSelector } from "react-redux";
import { refreshRefreshToken, refreshAccessToken } from "./redux/apiCalls";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Account from "./pages/Account";

const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route path="/products/:category">
          <ProductList/>
        </Route>
        <Route path="/products/">
          <ProductList/>
        </Route>
        <Route path="/product/:id">
          <Product/>
        </Route>
        <Route path="/cart">
          <Cart/>
        </Route>
        <Route path="/success">
          <Success/>
        </Route>
        <Route path="/login">
          {user ? <Redirect to="/"/> : <Login/>}
          <Login/>
        </Route>
        <Route path="/register">
        {user ? <Redirect to="/"/> : <Register/>}
          <Register/>
        </Route>
        <Route path="/account">
          <Account/>
        </Route>
      </Switch>
    </Router>
  )
};

export default App;