import grocery1 from '../src/assets/grocery1.png'
import shipping from '../src/assets/shipping.png'

import grocery from '../src/assets/grocery.png'

export const sliderItems = [
    {
      id: 1,
      img: grocery1,
      title: "Quality Groceries",
      desc: "From milk and bread to snacks and lottery tickets, we've got it all.",
      bg: "f1fbfd",
    },
    {
      id: 2,
      img: grocery,
      title: "Affordable Prices",
      desc: "Get what you need, at low and convenient prices.",
      bg: "f1fbfd",
    },
    {
      id: 3,
      img: shipping,
      title: "1-mile radius free shipping",
      desc: "Nearby but can't make it in=person? Shepherd Market is here to save the day.",
      bg: "f1fbfd",
    },
  ];

  export const categories = [
    {
      id: 1,
      img: "https://www.liquor.com/thmb/zzY3QbT5h9S9utBkexhECycXZIc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-519728153-7dca4b18c59f4b1fa3654e4d5c9db884.jpg",
      title: "BEER",
      cat: "beer"
    },
    {
      id: 2,
      img: "https://images.unsplash.com/photo-1621939514649-280e2ee25f60?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c25hY2tzfGVufDB8fDB8fA%3D%3D&w=1000&q=80",
      title: "SNACKS",
      cat: "food"
    },
    {
      id: 3,
      img: "https://www.coca-colacompany.com/content/dam/journey/au/en/private/2016/05/trace-the-130-year-evolution-of-the-coca-cola-logo-lead-939-456-ec747890.jpg",
      title: "SOFT DRINKS",
      cat: "soft-drinks"
    },
  ];

  export const popularProducts = [
    {
      id:1,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
    },
    {
      id:2,
      img:"https://www.jessicagavin.com/wp-content/uploads/2019/02/carrots-7-1200.jpg",
    },
    {
      id:3,
      img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
    },
    {
      id:4,
      img:"https://www.collinsdictionary.com/images/full/apple_158989157.jpg",
    },
    {
      id:5,
      img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
    },
    {
      id:6,
      img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
    },
    {
      id:7,
      img:"https://m.media-amazon.com/images/I/718sn7oOcfL.jpg",
    },
    {
      id:8,
      img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
    },
  ];