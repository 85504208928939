import React from 'react'
import Announcement from '../components/Announcement'
import Categories from '../components/Categories'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import FeaturedProducts from '../components/FeaturedProducts'
import Slider from '../components/Slider'
import { useSelector } from 'react-redux'
import UserNavbar from "../components/UserNavbar"

const Home = () => {
  const user = useSelector((state) => state.user.currentUser);

  return (
    <div>
      {user ? <UserNavbar/> : <Navbar/>}
        <Announcement/>
        <Slider/>
        <Categories/>
        <FeaturedProducts/>
        <Newsletter/>
        <Footer/>
    </div>
  )
}

export default Home