import { Badge } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Search, ShoppingCartOutlined } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import {mobile} from "../responsive"
import { halfScreen } from '../responsive'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Container = styled.div`
    height: 60px;
    ${mobile({ height: "30px"})}
    ${halfScreen({ height: "100px"})}
`

const Wrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${mobile({ padding: "10px 0px"})}
`

const Left = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    ${mobile({ display: "none" })}
`
//${mobile({ flex: 1.5, width: "100%"})}

const Language = styled.span`
    font-size: 14px;
    cursor: pointer;
    ${mobile({ display: "none"})}
`
const SearchContainer = styled.div`
    border: 0.5px solid lightgray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-left: 25px;
    padding: 5px;
`

const Input = styled.input`
    border: none;
    outline: none;
`

const Center = styled.div`
    flex: 1;
    text-align: center;
    
`
const Logo = styled.h1`
    font-weight: bold;
    ${mobile({ fontSize: "14px"})};
`

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${mobile({ flex: 3, justifyContent: "center"})}
`
const MenuItem = styled.div`
    font-size: 14px;
    cursor: pointer;
    margin-left: 25px;
    ${mobile({ fontSize: "12px", marginLeft: "10px"})}
`

const Navbar = () => {
  const quantity = useSelector(state=>state.cart.quantity);
  return (
    <Container>
        <Wrapper>
            <Left>
                <Language>EN</Language>
                <SearchContainer>
                    <Input/>
                    <Search style={{color: "gray", fontSize: 16}}/>
                </SearchContainer>
            </Left>
            <Center>
                <Logo>Shepherd Market</Logo>
            </Center>
            <Right>
                <Link to="/" style={{ textDecoration: 'none', color: "black" }}>
                    <MenuItem>HOME</MenuItem>
                </Link>
                {/* <MenuItem>ABOUT US</MenuItem>
                <MenuItem>SERVICES</MenuItem> */}
                <Link to="/products/" style={{ textDecoration: 'none', color: "black" }}>
                    <MenuItem>PRODUCTS</MenuItem>
                </Link>
                <Link to="/login" style={{ textDecoration: 'none', color: "black" }}>
                    <MenuItem>SIGN IN</MenuItem>
                </Link>
                <Link to = "/cart">
                    <MenuItem>
                        <Badge badgeContent={quantity} color="primary">
                            <ShoppingCartOutlined/>
                        </Badge>
                    </MenuItem>
                </Link>
            </Right>
        </Wrapper>
    </Container>
  )
}

export default Navbar