import { publicRequest } from "../requestMethods";
import { loginFailure, loginStart, loginSuccess } from "./userRedux" 
import { useSelector } from "react-redux";
import { removeProducts, changeOrderId } from "./cartRedux";
import { changeTokens } from "./userRedux";
import { changeAccessToken, changeRefreshToken } from "./userRedux";

export const login = async (dispatch, user) => {
    dispatch(loginStart());
    try{
        const res = await publicRequest.post("/auth/login",user);
        dispatch(loginSuccess(res.data));
    } catch(err) {
        dispatch(loginFailure());
    }
}

export const setOrderId = async (dispatch, orderId) => {
    try{
        dispatch(changeOrderId(orderId));
    } catch(err) {
        console.log(err);
    }
}

export const clearCart = async (dispatch, cart) => {
    try{
        dispatch(removeProducts());
    } catch(err) {
        console.log(err);
    }
}

export const refreshAccessToken = async (dispatch, newAccessToken) => {
    try{
        dispatch(changeAccessToken(newAccessToken));
    } catch(err) {
        console.log(err);
    }
}

export const refreshRefreshToken = async (dispatch, newRefreshToken) => {
    try{
        dispatch(changeRefreshToken(newRefreshToken));
    } catch(err) {
        console.log(err);
    }
}