import React from 'react'
import { useSelector } from 'react-redux'
import Announcement from '../components/Announcement'
import Navbar from '../components/Navbar'

const Account = () => {
  const user = useSelector((state)=> state.user.currentUser);

  return (
    <div >    
        <Navbar/>
        <Announcement/>
        <div style={{color:"f1fbfd", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <h1 >My Account</h1>
        </div>
        <div style={{marginTop: "30px", marginLeft: "30px"}}>
            <h3>Username: {user.others.username}</h3>
            <h3>Email: {user.others.email}</h3>
            <h3>Password: ------------ <button>Reset Password</button></h3>
        </div>
    </div>
  )
}

export default Account