import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: null,
        isFetching: false,
        error: false,
    },
    reducers: {
        loginStart:(state)=>{
            state.isFetching=true;
        },
        loginSuccess:(state,action)=>{
            state.isFetching = false;
            state.currentUser=action.payload;
        },
        loginFailure:(state)=>{
            state.isFetching=false;
            state.error=true;
        },
        changeAccessToken: (state, action)=>{
            //console.log("before: "+state.currentUser.accessToken);
            state.currentUser.accessToken = action.payload;
            //console.log("after: "+state.currentUser.accessToken);
        },
        changeRefreshToken: (state, action)=>{
            //console.log("before: "+state.currentUser.refreshToken);
            state.currentUser.refreshToken = action.payload;
            //console.log("after: "+state.currentUser.refreshToken);

        }
    },
});

export const { loginFailure, loginStart, loginSuccess, changeAccessToken, changeRefreshToken } = userSlice.actions;
export default userSlice.reducer;