import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearCart } from '../redux/apiCalls';
import { refreshAccessToken, refreshRefreshToken } from '../redux/apiCalls';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { store } from '../redux/store';
import { publicRequest } from '../requestMethods';
import emailjs from 'emailjs-com'

const Success = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  var data = null;
  const [confirm, setConfirm] = useState(null)

  //const data = location.state.stripeData;
  const cart = useSelector(state=>state.cart);
  const orderId = useSelector(state=>state.cart.orderId)
  //const [orderId, setOrderId] = useState(null);
  var currentUserId = "";
  if (store.getState().user.currentUser){
    currentUserId = store.getState().user.currentUser.others._id;
  }

  const prods = cart.products.slice();
  console.log(prods)

  const start = 1;

  //const TOKEN = user?.refreshToken;

  //var newAccessToken = store.getState().user.currentUser.accessToken;
  //console.log("here da token " + newAccessToken)

  useEffect(() => {
    const getSession = async () => {
      console.log("getting session...")
      console.log(orderId)
      try {
        const res = await publicRequest.post("/checkout/finish", {
          sessionId: orderId,
        }).then((res) => {
          console.log("here da session ")
          console.log(res.data)
          data = res.data
          console.log(data)
          const createOrder = async () => {
            console.log("creating order..")
            try {
              //sendEmail();
              const res = await publicRequest.post("/orders", {
                  userId: currentUserId,
                  products: prods.map((item) => ({
                  productId: item._id,
                  title: item.title,
                  quantity: item._quantity,
                })),
                amount: cart.total,
                address: data.address,
                email: data.email,
                name: data.name,
              });
              setConfirm(res.data._id);
              const res2 = await publicRequest.post("/emails", {
                email: data.email,
                orderId: res.data._id,
                products: prods,
              }).then((res) => {
                console.log(res);
                clearCart(dispatch,{ cart });
              });
              
            } catch(err) {
              console.log(err);
              clearCart(dispatch,{ cart });
            }
          };
          console.log("data is " + data)
          data && createOrder();
        })
      } catch(err) {console.log(err)}
    }
    getSession();
  }, []);


  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {confirm
        ? `Order has been successfully created! Your order number is ${confirm}. Please keep this safe somewhere in case of any issue.`
        : `Successful. Your order is being prepared...`}
        <Link to="/" style={{ textDecoration: 'none', color:"black" }}><button style={{ padding: 10, marginTop: 20 }}>Back to Homepage</button></Link>
      
    </div>
  );
}

export default Success;