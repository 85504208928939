import React from 'react'
import styled from 'styled-components'
import {mobile} from "../responsive"
import { halfScreen } from '../responsive'


const Container = styled.div`
    height: 30px;
    background-color: teal;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    ${mobile({ fontSize: "12px"})}
    ${halfScreen({ height: "50px"})}
`

const Announcement = () => {
  return (
    <Container>
       In observation of President's Day, our operating hours for tomorrow will be 10AM-8PM. Thank you for your understanding.
    </Container>
  )
}

export default Announcement