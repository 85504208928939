import styled from "styled-components"
import Navbar from "../components/Navbar"
import Announcement from "../components/Announcement"
import Newsletter from "../components/Newsletter"
import Footer from "../components/Footer"
import { Add, Remove } from "@material-ui/icons"
import {mobile} from "../responsive"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { publicRequest } from "../requestMethods"
import { addProduct } from "../redux/cartRedux"
import { useDispatch } from "react-redux"


const Container = styled.div`

`
const Wrapper = styled.div`
    padding: 50px;
    display: flex;
    ${mobile({ padding: "10px", flexDirection: "column"})}
`

const ImgContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Image = styled.img`
    height: 50vh;
    object-fit: cover;
    ${mobile({ height: "30vh"})}
`

const InfoContainer = styled.div`
    flex: 1;
    padding: 0px 50px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    ${mobile({ padding: "0px 10px"})}
`

const Title = styled.h1`
    font-weight: 200;
`

const Desc = styled.p`
    margin: 20px 0px;
`

const Price = styled.span`
    font-weight: 100;
    font-size: 40px;
`

const AddContainer = styled.div`
    margin-top: 25px;
    width: 50%;
    display: flex;
    align-items: center;
    ${mobile({ width: "100%", justifyContent: "space-between"})}
`

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-right: 50px;
`

const Amount = styled.span`
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
`

const Button = styled.button`
    padding: 15px;
    border: 2px solid teal;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    transition: all 0.5s ease;

    &:hover{
        background-color: #f8f4f4;
    }
` 

const Product = () => {
const location = useLocation();
const id = location.pathname.split("/")[2];
const [product, setProduct] = useState({});
const [quantity, setQuantity] = useState(1);
const dispatch = useDispatch();

useEffect(()=> {
    const getProduct = async () => {
        try {
            const res = await publicRequest.get("/products/find/"+id);
            setProduct(res.data);
        } catch {}
    };
    getProduct();
}, [id]);

const handleQuantity = (type) => {
    if (type === "dec"){
        quantity > 1 && setQuantity(quantity-1);
    } else {
        setQuantity(quantity+1);
    }
};

//update cart

const handleClick = () => {
    dispatch(addProduct({ ...product, quantity }));
};

  return (
    <Container>
        <Navbar/>
        <Announcement/>
        <Wrapper>
            <ImgContainer>
                <Image src = {product.img}/>
            </ImgContainer>
            <InfoContainer>
                <Title>{product.title}</Title>
                <Desc>{product.desc}</Desc>
                <Price>{product.price}</Price>
                <AddContainer>
                    <AmountContainer>
                        <Remove cursor = "pointer" onClick={() => handleQuantity("dec")} />
                        <Amount>{quantity}</Amount>
                        <Add cursor = "pointer" onClick={() => handleQuantity("inc")}/>
                    </AmountContainer>
                    <Button onClick={handleClick}>Add to Cart</Button>
                </AddContainer>
            </InfoContainer>
        </Wrapper>
        <Newsletter/>
        <Footer/>
    </Container>
  )
}

export default Product