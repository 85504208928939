import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { login } from "../redux/apiCalls"
import { mobile } from "../responsive"
import { MenuItem } from "@material-ui/core"
import { Link } from 'react-router-dom'


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        rgba(255,255,255,0.3), 
        rgba(255,255,255,0.3)
    ), 
    url("https://images.unsplash.com/photo-1577374559080-cb697b79d8f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
    width: 40%;
    padding: 20px;
    background-color: white;
    ${mobile({ width: "75%"})}
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
`

const Title = styled.h1`
    font-size: 24px;
    font-weight: 300;
`

const Input = styled.input`
    flex: 1;
    min-width: 40%;
    margin: 20px 10px 0px 0px;
    padding: 15px;
    font-size: 20px;
`

const Button = styled.button`
    width: 100%;
    border: none;
    padding: 15px 20px;
    background-color: teal;
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin: 20px 0px;
    &:disabled{
        color: grey;
        cursor: not-allowed;
    }
`

const Linkx = styled.a`
    margin: 5px 0px;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
`

const MiniContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 30px;
`

const Error = styled.span`
    color: red;
`

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const {isFetching, error} = useSelector(state=>state.user);

  const handleClick = (e) => {
    e.preventDefault();
    login(dispatch,{ username,password });
  }

  return (
    <Container>
        <Wrapper>
            <Title>Sign In</Title>
            <Form>
                <Input placeholder="username" onChange={(e)=> setUsername(e.target.value)}/>
                <Input placeholder="password" type = "password" onChange={(e)=> setPassword(e.target.value)}/>
                <Button onClick={handleClick} disabled={isFetching}>Log In</Button>
                {error && <Error>Something went wrong. Beep...</Error>}
                <MiniContainer>
                    <Link><Linkx>Forgot Password</Linkx></Link>
                    <Link to="/register"><Linkx>Create Account</Linkx></Link>
                </MiniContainer>
            </Form>
        </Wrapper>
    </Container>
  )
}

export default Login