import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {sliderItems} from '../data'
import {mobile} from "../responsive"

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    overflow: hidden;
    ${mobile({ display: "none"})}
`

const Arrow = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff7f7;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${props => props.direction === "left" && "10px"};
    right: ${props => props.direction === "right" && "10px"};
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    z-index: 2;
`

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    transform: translateX(${(props) => props.slideIndex * -100}vw);
    transition: all 1.5s ease;
`

const Slide = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-color: #${(props) => props.bg};
`

const ImageContainer = styled.div`
    height: 70%;
    width: 50%;
    display: flex;
    margin-left: 65px;
    align-items: center;
`

const Image = styled.img`
    height: 70%;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    margin: auto;
`

const InfoContainer = styled.div`
    flex: 1;
    margin-right: 120px;
    align-items: center;
`

const Title = styled.h1`
    font-size: 78px;
    justify-content: center;
`
 
const Desc = styled.p`
    margin: 50px 0px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 3x;
`

const Button = styled.button`
    padding: 10px;
    font-size: 20px;
    background-color: transparent;
    border-width: 3px;
    cursor: pointer;
`

const Slider = () => {

    const [slideIndex, setSlideIndex] = useState(0);

    const handleClick = (direction) => {
        if((direction) === "left") {
            setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2)
        } else {
            setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0)
        }
    }
  return (
    <Container>
        <Arrow direction="left" onClick={() => handleClick("left")}>
            <ArrowBackIosOutlined/>
        </Arrow>
        <Wrapper slideIndex = {slideIndex}>
            {sliderItems.map(item=>(
                <Slide bg = {item.bg} key = {item.id}>
                    <ImageContainer>
                        <Image src = {item.img}/>
                    </ImageContainer>
                    <InfoContainer>
                        <Title>
                            {item.title}
                        </Title>
                        <Desc>
                            {item.desc}
                        </Desc>
                        <Link to="/products/">
                            <Button>
                                Shop Now
                            </Button>
                        </Link>
                    </InfoContainer>
                </Slide>
             ))}
        </Wrapper>
        <Arrow direction="right" onClick={() => handleClick("right")}>
            <ArrowForwardIosOutlined/>
        </Arrow>     
    </Container>
  )
}

export default Slider