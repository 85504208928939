import { Facebook, Instagram, MailOutline, Phone, Pinterest, Room, Twitter } from "@material-ui/icons"
import styled from "styled-components"
import {mobile} from "../responsive"

const Container = styled.div`
    display: flex;
    ${mobile({ flexDirection: "column", padding: "15px"})};
`

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;`

const Logo = styled.h1`
`

const Description = styled.p`
    margin: 20px 0px;
`
const SocialContainer = styled.div`
    display: flex;
`
const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    text-decoration: none;
`

const Center = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ display: "none"})}
`

const Title= styled.h3`
    margin-bottom: 30px;
`

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

`

const ListItem = styled.li`
    width: 50%;
    margin-bottom: 10px;
`

const Right = styled.div`
    flex: 1;
    padding: 20px;
`

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`

const Payment = styled.img`
    width: 50%;
`

const Linker = styled.a`
    color: white;
`
const Linker2 = styled.a`
    color: black;
    text-decoration: none;
`

const Footer = () => {
  return (
    <Container>
        <Left>
            <Logo>Shepherd Market</Logo>
            <Description>
                Your local convenience store with everything you'll ever need.
            </Description>
            <SocialContainer>
                <SocialIcon color="3B5999">
                    <Linker target="_blank" href="https://www.facebook.com/profile.php?id=100088571318929">
                        <Facebook/>
                    </Linker>                
                </SocialIcon>
                <SocialIcon color="E4405F">
                    <Linker target="_blank" href="https://www.instagram.com/shepherdmarket20011/">
                        <Instagram/>
                    </Linker> 
                </SocialIcon>
                <SocialIcon color="55ACEE">
                    <Linker target="_blank" href="https://www.twitter.com/ShepherdMarket2">
                        <Twitter/>
                    </Linker> 
                </SocialIcon>
                <SocialIcon color="E60023">
                    <Pinterest/>
                </SocialIcon>
            </SocialContainer>
        </Left>
        <Center>
            <Title>Useful Links</Title>
            <List>
                <ListItem>Home</ListItem>
                <ListItem>About Us</ListItem>
                <ListItem>Products</ListItem>
                <ListItem>Services</ListItem>
                <ListItem>My Account</ListItem>
                <ListItem>Terms</ListItem>
            </List>
        </Center>
        <Right>
            <Title>Contact</Title>
            <ContactItem>
                <Room style={{marginRight: "10px"}}/>
                1247 Shepherd St NW, Washington, D.C., 20011
            </ContactItem>
            <ContactItem>
                <Phone style={{marginRight: "10px"}}/>
                +1 (202) 722-5036
            </ContactItem>
            <ContactItem>
                <MailOutline style={{marginRight: "10px"}}/>
                <Linker2 href="mailto:info@shepherd-market.com">info@shepherd-market.com</Linker2>
            </ContactItem>
            <Payment src="https://i.ibb.co/Qfvn4z6/payment.png"/>
        </Right>
    </Container>
  )
}

export default Footer