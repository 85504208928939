import {createSlice} from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
        orderId: "",
    },
    reducers: {
        addProduct: (state, action)=> {
            var check = false;
            console.log(action.payload);
            state.products.forEach((item) => {
                if (item._id === action.payload._id) {
                    console.log("already in cart");
                    item.quantity += action.payload.quantity;
                    state.total += action.payload.price * action.payload.quantity;
                    check = true;
                }
            })
            if (!check){
                state.products.push(action.payload);
                state.total += action.payload.price *action.payload.quantity;
            }
            state.quantity += action.payload.quantity;
        },

        changeOrderId: (state, action)=> {
            state.orderId = action.payload;
        },

        removeProducts: (state)=> {
            state.quantity = 0;
            state.products = [];
            state.total = 0;
            state.orderId = "";
            console.log("products removed!")
        }
    },
});

export const { addProduct, removeProducts, changeOrderId } = cartSlice.actions;
export default cartSlice.reducer;